<template>
  <div
    class="
      Flow
      border-round-12
      bg-white
      margin-top
      padding-lr-xl padding-top-lg padding-bottom-lg
      box-ing
      margin-bottom
      padding-bottom
    "
  >
    <i
      class="icon status"
      v-if="Number(checkResult) === 1 && isSuperior"
      :class="superiorIcon[6]"
    />
    <i
      class="icon status"
      v-else-if="isSuperior && Number(checkResult) !== 1"
      :class="superiorIcon[Number(propsPlanState || planState) - 2]"
    />
    <i
      v-else
      class="icon status"
      :class="planStateIcons[Number(propsPlanState || planState) - 2]"
      style="top: -4px"
    />
    <!--    {  'status-approve': planState==='2','status-success': planState==='4'||planState==='7' }-->
    <h3>流程</h3>
    <div class="today-step">
      <div
        class="step-container padding-bottom"
        v-for="(item, index) in stepList"
        :key="index"
      >
        <!--        当item 是数组时代表 这是会审人-->
        <template v-if="!isNaN(item.length)">
          <div class="step-round">
            <img :src="multiplayer" />
          </div>
          <div class="step-context padding-bottom" style="margin-left: 66px">
            <div class="flex justify-between">
              <div>
                <h4>会审人</h4>
                <p>{{ item.length }}人会审</p>
              </div>
            </div>
            <div v-for="(info, i) in item" :key="i" class="margin-top-sm">
              <p style="text-align: right">{{ info.createTime }}</p>
              <div class="flex align-center justify-between">
                <div class="flex margin-top align-center">
                  <div
                    class="step-multiplayer-img margin-right-sm"
                    style="min-width: 40px; min-height: 40px"
                  >
                    <img v-if="info.avatar?.length !== 0" :src="info.avatar" />
                    <span v-else> {{ info.name.slice(-2) }}</span>
                    <i class="icon" :class="processIcons[info.checkResult]" />
                  </div>
                  <div class="remark">
                    {{ info.remark }}
                  </div>
                </div>
              </div>
              <p class="margin-top-sm">{{ info.name }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="step-round text-center">
            <img v-if="item.avatar?.length !== 0" :src="item.avatar" />
            <span v-else> {{ item.name.slice(-2) }}</span>
            <i class="icon" :class="processIcons[item.checkResult]" />
          </div>
          <div class="step-context padding-bottom" style="margin-left: 66px">
            <div class="flex justify-between">
              <div>
                <h4>{{ index === 0 ? "发起人" : "审批人" }}</h4>
                <p>{{ item.name }}</p>
              </div>
              <p>{{ index === 0 ? item.publishTime : item.createTime }}</p>
            </div>
            <div
              class="remark"
              v-if="item.remark !== '' && item.remark !== null"
            >
              {{ item.remark }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="path !== '/mobileApproval'">
      <div class="flex justify-center" v-if="isShow">
        <el-button
          @click="open('拒绝', isJoinAssessor)"
          v-if="
            (userid === '0333266228557604405' && Number(planState) === 2) ||
            type === 0
          "
          >拒绝</el-button
        >
        <el-button type="primary" @click="open('通过', isJoinAssessor)"
          >通过</el-button
        >
      </div>
      <el-button
        v-if="
          (Number(planState) === 1 ||
            Number(planState) === 3 ||
            Number(planState) === 5) &&
          !isSuperior
        "
        type="primary"
        round
        @click="handleOpen"
        :loading="isLoading"
        >提交
      </el-button>
    </div>

    <el-dialog
      :title="titleType"
      v-model="dialogFormVisible"
      :before-close="cancel"
    >
      <el-form :model="form" ref="formRef">
        <el-form-item prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="form.remark"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="提示"
      v-model="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>是否提交?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleDetermine">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { personList, updatePlanState } from '@/api/user'
import { useStore } from '@/vuex/store'
import multiplayer from '@/assets/images/multiplayer.svg'
import { ElMessage } from 'element-plus'
// import { ElLoading } from 'element-plus'

export default {
  name: 'Flow',
  props: {
    isLoading: Boolean,
    propsPlanState: Number,
    isJoinAssessor: {
      type: Number,
      default: 1
    },
    type: Number,
    submit: Function
  },
  setup (props) {
    const formRef = ref(null)
    const route = useRoute()
    const store = useStore()
    const planState = ref('2')
    const isSuperior = ref(false)
    const stepList = ref([])
    const title = ref('通过')
    const userid = ref(JSON.parse(sessionStorage.getItem('user')).userid)
    const dialogFormVisible = ref(false)
    const isShow = ref(false)
    const path = ref('./')
    const dialogVisible = ref(false)
    const titleType = ref('')
    const checkResult = ref(0)
    const form = ref({
      remark: ''
    })
    // 上级查看时图标
    const superiorIcon = [
      'status-approve',
      'status-rejected',
      'status-audited',
      'status-self-evaluated',
      'status-reEvaluated',
      'status-success',
      'status-beenApproved'
    ]
    // 员工自己查看时的图标
    const planStateIcons = [
      'status-pending',
      '',
      'status-audited',
      '',
      'status-reEvaluated',
      'status-success'
    ]
    const processIcons = [
      'process-audit',
      'process-success',
      'process-failure',
      ''
    ]
    const getPersonList = () => {
      const userid = route.query.userid || store.state.user.userid
      personList({
        planId: route.query.id,
        userid
      }).then((res) => {
        // loading.close()
        if (res.code === 200) {
          stepList.value = res.data
          console.log(res.data)
          store.action.updateRemark('')
          isShow.value = false
          res.data.forEach((item) => {
            if (item.userid === userid && item.checkResult === 1) {
              checkResult.value = item.checkResult
              console.log(item.checkResult)
            }
          })
          if (
            Number(route.query.planState) === 2 &&
            (route.query.isSuperior || route.path === '/mobileApproval')
          ) {
            res.data.forEach((item) => {
              // 当item 是数组时判读会审人员
              if (item instanceof Array) {
                item.forEach((i) => {
                  if (i.userid === userid && i.checkResult === 0) {
                    isShow.value = true
                  }
                })
              }
              // 判断是否为审核人员
              if (item.userid === userid && item.checkResult === 0) {
                isShow.value = true
              }
            })
          }
          store.action.updateIsShow(isShow.value)
          if (Number(route.query.planState) === 3) {
            res.data.forEach((item) => {
              if (item.checkResult === 2) {
                store.action.updateRemark(item.remark)
              }
            })
          }
        }
      })
    }
    const open = (e) => {
      console.log(props.type)
      title.value = e
      dialogFormVisible.value = true
    }
    const handleOpen = () => {
      dialogVisible.value = true
    }
    const handleClose = () => {
      dialogVisible.value = false
    }
    const handleDetermine = () => {
      props.submit()
      handleClose()
    }
    const cancel = () => {
      dialogFormVisible.value = false
      formRef.value.resetFields()
    }
    const determine = () => {
      if (
        ((props.isJoinAssessor === 2 && title.value === '拒绝') ||
          props.isJoinAssessor !== 2) &&
        form.value.remark.length === 0
      ) {
        return ElMessage.error(`${title.value}理由必填`)
      }
      dialogFormVisible.value = false
      const { userid } = store.state.user
      updatePlanState({
        // 19500433631186346
        // 2257151052990977
        approvePersonId: userid,
        planId: route.query.id,
        remark: form.value.remark,
        checkResult: title.value === '通过' ? 1 : 2
      }).then((res) => {
        if (res.code === 200) {
          ElMessage.success('审批成功')
          formRef.value.resetFields()
          store.action.updateIsDetail(true)
        }
      })
    }
    watchEffect(() => {
      if (route.query.isSuperior) {
        isSuperior.value = route.query.isSuperior
      }
    })
    watchEffect(() => {
      if (route.query.planState) {
        planState.value = route.query.planState
        path.value = route.path
        getPersonList()
      }
    })
    watchEffect(() => {
      if (store.state.isDetail) {
        getPersonList()
        store.action.updateIsDetail(false)
      }
    })

    watchEffect(() => {
      if (props.isJoinAssessor === 1) {
        titleType.value = '会审意见'
      }
      if (props.isJoinAssessor === 0 || props.isJoinAssessor === 2) {
        titleType.value = '审批意见'
      }
    })
    return {
      planState,
      isSuperior,
      stepList,
      multiplayer,
      planStateIcons,
      title,
      form,
      dialogFormVisible,
      cancel,
      formRef,
      determine,
      open,
      isShow,
      superiorIcon,
      path,
      processIcons,
      route,
      dialogVisible,
      handleClose,
      handleDetermine,
      handleOpen,
      titleType,
      checkResult,
      userid
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.Flow {
  //min-height:calc(100vh - 170px);

  overflow: auto;
  position: relative;

  .el-button--small::v-deep {
    padding: 12px 56px;
    font-size: 16px;
  }
}

.today-step {
  padding-top: 30px;

  span {
    color: #fff;
  }

  .step-container {
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 30px;
      left: 18px;
      height: 100%;
      width: 4px;
      background: #dce3eb;
    }
  }

  .step-container:last-child::before {
    display: none;
  }

  .step-context {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;

    span {
      font-size: 16px;
    }
  }

  h4 {
    margin-bottom: 4px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  .step-round {
    position: absolute;
    left: 0;
    width: 42px;
    height: 42px;
    background: #1890ff;
    border-radius: 8px;
    line-height: 42px;

    img {
      height: 42px;
      border-radius: 8px;
      width: 42px;
    }

    i {
      width: 14px;
      height: 14px;
      position: absolute;
      right: -2px;
      bottom: 0;
    }
  }

  .step-multiplayer-img {
    position: relative;
    background: #1890ff;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;

    span {
      font-size: 10px;
    }

    img {
      height: 40px;
      width: 40px;
      border-radius: 8px;
    }

    i {
      width: 14px;
      height: 14px;
      position: absolute;
      right: -2px;
      bottom: 0;
    }
  }

  .remark {
    padding: 8px;
    background: #f1f4f5;
    border-radius: 8px;
  }
}
</style>
