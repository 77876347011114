<template>
  <div class="headquarterss">
    <div class="headline">月绩效管理</div>
    <div class="search">
      <div class="block" style="margin-left: 41px">
        <el-date-picker
          v-model="selectionTime"
          type="month"
          @change="timing"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="select_sh">
        <el-select v-model="departmentId" placeholder="请选择部门">
          <el-option
            v-for="item in optionsList"
            :key="item.departmentId"
            :label="item.departmentName"
            :value="item.departmentId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="input_sh">
        <el-input placeholder="请输入姓名" v-model="name" clearable> </el-input>
      </div>
      <div class="input3">
        <el-select v-model="planState" placeholder="请选择状态">
          <el-option
            v-for="item in titleList"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="button_cx">
        <el-button type="primary" @click="inquire">查询</el-button>
      </div>
      <div class="button_cz">
        <el-button type="primary" @click="reset" plain>重置</el-button>
      </div>
    </div>
    <div class="tabe_class">
      <el-table :data="tableData" style="width: 96%">
        <el-table-column prop="id" label="序号" width="100"> </el-table-column>
        <el-table-column prop="userName" label="姓名" width="150">
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" width="300">
        </el-table-column>
        <el-table-column prop="position" label="岗位" width="250">
        </el-table-column>
        <el-table-column
          prop="selfAssessScore"
          label="自评得分"
          sortable
          width="125"
        >
        </el-table-column>
        <el-table-column prop="score" label="领导得分" sortable width="125">
        </el-table-column>
        <el-table-column prop="planState" label="状态" width="150">
          <template #default="data">
            <span
              class="dian"
              :class="data.row.planState > 1 ? 'green' : 'red'"
            ></span>
            <span>{{ titles[data.row.planState - 1] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="month" label="日期" width="153">
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template #default="date">
            <div class="inline">
              <el-button
                size="mini"
                type="text"
                v-if="+date.row.planState === 1"
                @click="
                  () => {
                    remind(date);
                  }
                "
                >提醒</el-button
              >
            </div>
            <div class="inline">
              <el-button
                size="mini"
                type="text"
                @click="
                  () => {
                    handleEdit(date);
                  }
                "
                >退回</el-button
              >
            </div>
            <div class="inline">
              <el-button
                size="mini"
                type="text"
                @click="
                  () => {
                    handleSet(date);
                  }
                "
                >通过</el-button
              >
            </div>
            <div class="inline">
              <el-button
                size="mini"
                type="text"
                @click="
                  () => {
                    handleck(date.row);
                  }
                "
                >查看</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="提示"
      v-model="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span> 确认修改此状态？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <div class="paginationY">
      <el-pagination
        :page-size="10"
        :pager-count="7"
        background
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-drawer title="详情" v-model="drawer" destroy-on-close>
        <staffDrawer :planId="planId" :userId="userId" />
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watchEffect } from 'vue'
import { department, viewMonthPlan, planUpdate, sendNotice } from '@/api/user'
import { ElMessage } from 'element-plus'
import staffDrawer from '../drawer/staffDrawer'
export default {
  name: 'staffManagement',
  setup () {
    const page = ref(1)
    const tableData = ref([])
    const total = ref(0)
    const name = ref('')
    const planId = ref('')
    const userId = ref('')
    const departmentId = ref('')
    const selectionTime = ref('')
    const planState = ref('')
    const drawer = ref(false)
    const titles = ref([
      '未提交',
      '待审核',
      '拒绝',
      '同意',
      '待自评',
      '待复评',
      '完成'
    ])
    const titleList = ref([
      { text: '未提交', id: 1 },
      { text: '待审核', id: 2 },
      { text: '拒绝', id: 3 },
      { text: '同意', id: 4 },
      { text: '待自评', id: 5 },
      { text: '待复评', id: 6 },
      { text: '完成', id: 7 },
      { text: '已提交', id: 8 },
      { text: '已提交', id: '6,7' },
      { text: '未提交', id: '1,2,3,4,5' }
    ])
    const optionsList = ref([])
    const dialogVisible = ref(false)
    const list = ref({
      planId: '',
      planState: 0
    })
    // 获取页面数据
    const getList = () => {
      viewMonthPlan({
        currPage: page.value,
        pageSize: 10,
        month: selectionTime.value ? selectionTime.value : '',
        userName: name.value,
        departmentId: departmentId.value,
        planState: planState.value === 8 ? '2,4,5,6,7' : planState.value
      }).then((res) => {
        if (res.code === 200) {
          tableData.value = res.data.list
          total.value = res.data.totalCount
          sessionStorage.removeItem('item')
        }
      })
    }
    // 获取部门列表
    const getDepartment = () => {
      department({}).then((res) => {
        if (res.code === 200) {
          optionsList.value = res.data
        }
      })
    }
    // 更改任务状态---通过
    const handleSet = (data) => {
      console.log(data)
      list.value.planId = data.row.planId
      list.value.planState = 7
      dialogVisible.value = true
    }
    // 更改任务状态 --拒绝
    const handleEdit = (data) => {
      console.log(data)
      list.value.planId = data.row.planId
      list.value.planState = 1
      dialogVisible.value = true
    }
    // 确定更改状态
    const confirm = () => {
      planUpdate({
        ...list.value
      }).then((res) => {
        if (res.code === 200) {
          ElMessage.success('状态修改成功')
          dialogVisible.value = false
          getList()
        }
      })
    }
    // 关闭弹窗
    const close = () => {
      dialogVisible.value = false
    }
    // 查询
    const inquire = () => {
      getList()
    }
    // 重置
    const reset = () => {
      page.value = 1
      name.value = ''
      departmentId.value = ''
      selectionTime.value = ''
      planState.value = ''
      getList()
    }
    // 翻页
    const currentChange = (e) => {
      if (e) {
        page.value = e
        getList()
      }
    }
    const handleck = (e) => {
      if (e) {
        console.log(e)
        planId.value = e.planId
        userId.value = e.userid
        drawer.value = true
      }
      console.log(e)
    }
    // 提醒
    const remind = (e) => {
      if (e && e.row.month && e.row.userid) {
        sendNotice({
          month: e.row.month,
          userid: e.row.userid
        }).then((res) => {
          if (res.code === 200) {
            console.log(11111111)
            ElMessage.success('提醒成功')
          } else {
            ElMessage.error('提醒失败')
          }
        })
      }
    }
    const timing = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        const time = y + '-' + m
        selectionTime.value = time
      }
    }
    onMounted(() => {
      if (JSON.parse(sessionStorage.getItem('item'))) {
        selectionTime.value = JSON.parse(sessionStorage.getItem('item')).t
        console.log()
        if (JSON.parse(sessionStorage.getItem('item')).id === '2,4,5,6,7') {
          planState.value = 8
        } else {
          planState.value = JSON.parse(sessionStorage.getItem('item')).id
        }
      }
      getList()
      getDepartment()
    })
    return {
      optionsList,
      selectionTime,
      timing,
      tableData,
      handleck,
      getList,
      getDepartment,
      page,
      currentChange,
      total,
      titles,
      name,
      departmentId,
      titleList,
      planState,
      inquire,
      reset,
      drawer,
      planId,
      remind,
      handleSet,
      dialogVisible,
      confirm,
      close,
      list,
      handleEdit,
      userId
    }
  },
  components: {
    staffDrawer
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.headquarterss {
  .headline {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding-left: 200px;
  }
  .search {
    display: flex;
    .select_sh {
      width: 224px;
      margin-left: 40px;
    }
    .input_sh {
      width: 224px;
      margin-left: 12px;
    }
    .input3 {
      width: 224px;
      margin-left: 23px;
    }
    .button_cx {
      margin-left: 30px;
    }
    .button_cz {
      margin-left: 10px;
    }
  }
  .el-input--small .el-input__inner {
    width: 100%;
    height: 40px;
    line-height: 32px;
  }
  .el-drawer.rtl {
    width: 70% !important;
  }

  .el-button--text {
    font-size: 16px;
    width: 50px;
  }
  .el-drawer__header {
    margin-bottom: 16px !important;
  }
  .el-table--small td,
  .el-table--small th {
    padding: 10px 0;
    font-size: 16px;
  }
  .el-button {
    width: 60px;
    font-size: 14px !important;
    border-right: 1px solid #d3d8dd;
  }
  .el-button--small {
    padding: 9px 15px;
    font-size: 16px;
    border-radius: 3px;
    width: 80px;
    height: 40px;
  }
  .paginationY {
    width: 100%;
    text-align: center;
    margin-right: 50px;
    margin-top: 40px;
  }
  .el-table th {
    background-color: #e6ebf0;
    color: #000;
    font-size: 14px;
  }
  .inline {
    width: 60px;
    display: inline-block;
  }
  .tabe_class {
    margin-left: 195px;
    margin-top: 20px;
    .dian {
      margin-right: 8px;
      content: " ";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-top: 9px;
    }
    .green {
      background-color: #52c41a;
    }
    .red {
      background-color: red;
    }
  }
}
</style>
