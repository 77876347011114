<template>
  <!--      当上级查看下级展示-->
  <div class="flex margin-top info" style="color: rgba(0, 0, 0, 0.85);">
    <div class="flex align-center" style="width:960px;">
      <p class="PerformanceDetails-name margin-right-lg">{{ planList.publishName }}</p>
      <p class="PerformanceDetails-position">{{ planList.departmentName }} —— {{ planList.position }}</p>
    </div>
  </div>
  <div class="PerformanceDetails flex  justify-center "
    :style="{'height':isSuperior?'calc(100vh - 130px)':'calc(100vh - 80px)'}">
    <div
      class=" box-ing content-box margin-top border-round-12"
      style="position:relative"
    >
      <i v-if="planState===5 &&!isSuperior" class="icon status status-self-evaluated"/>
      <el-form :model="planList" label-width="100px" ref="planRef" class="PerformanceDetails-form">
        <div class="bg-white border-round-12 padding-tb padding-lr-xl"
             >
          <div v-for="(item, index) in planList.info" :key="index"
               class="PerformanceDetails-form-box padding-bottom margin-bottom-lg">
            <div class="flex justify-between margin-bottom">
              <div>目标({{ index + 1 }})</div>
            </div>
            <el-form-item
              :prop="'info.' + index + '.corePerformanceIndicators'"
              label="核心工作指标 :"
            >
              <div>{{ item.corePerformanceIndicators }}</div>
            </el-form-item>
            <el-form-item
              :prop="'info.' + index + '.indicatorsStandardValue'"
              label="指标标准值 :"
            >
              <div>{{ item.indicatorsStandardValue }}</div>
            </el-form-item>
            <el-form-item
              :prop="'info.' + index + '.standardEvaluation'"
              label="评分标准 :"
            >
              <div>{{ item.standardEvaluation }}</div>
            </el-form-item>
            <div class="flex justify-between">
              <el-form-item
                :prop="'info.' + index + '.planTime'"
                label="计划用时(h) :"
              >
                <div>{{ item.planTime }}</div>
              </el-form-item>
              <el-form-item
                :prop="'info.' + index + '.weight'"
                label="权重(配分100) :"
              >
                <div>{{ item.weight }}</div>
              </el-form-item>
              <el-form-item
                :prop="'info.' + index + '.dataFromDepartment'"
                label="数据来源部门 :"
              >
                <div>{{ item.dataFromDepartment }}</div>
              </el-form-item>
              <el-form-item
                :prop="'info.' + index + '.evaluationScores'"
                :rules="[{ required: planState===5, message: '得分必填', trigger: 'blur'}]"
                label="得分 :">
                <div style="color: #fb4343">{{ item.evaluationScores }}</div>
              </el-form-item>
            </div>
            <div v-if="(Number(planState) >= 5 && planUser === userid) || (Number(planState) > 5)">
              <el-form-item
                :prop="'info.' + index + '.completion'"
                :rules="[{ required: planState===5, message: '完成情况', trigger: 'blur'}]"
                label="完成情况 :"
              >
                <el-input type="textarea" :rows="2" placeholder="请输入完成情况" v-if="planState===5 && planUser === userid" v-model="item.completion"/>
                <div v-else>{{ item.completion }}</div>
              </el-form-item>
              <div style="margin-bottom: 30px">
                <div style="margin: 20px 0">
                  <span>图片:</span>
                  <span class="zdxz">(最多选择9张)</span>
                </div>
                <el-upload
                  class="upload-demo"
                  action
                  :before-upload="(e)=>{upload(e,index)}"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-exceed="exceedImg"
                  :file-list="item.pictures || []"
                  :disabled="planUser !== userid || (Number(planState) !== 5)"
                  limit=9
                  list-type="picture-card"
                >
                  <template #default>
                    <i class="el-icon-plus"></i>
                  </template>
                  <template #file="{file}">
                    <div>
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                      <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                    </div>
                  </template>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                  <img style="width: 100%;height: 50vh" :src="dialogImageUrl" alt="">
                </el-dialog>
              </div>
              <div>
                <el-upload
                  class="upload-demo"
                  action
                  :before-upload="(e)=>{upload2(e,index)}"
                  :on-preview="handlePreview2"
                  :on-remove="handleRemove2"
                  :on-exceed="onExceed"
                  :file-list="item.attachment || []"
                  :disabled="planUser !== userid || (Number(planState) !== 5)"
                  limit=9
                >
                  <span>附件:</span>
                  <el-button class="sctup">添加附件</el-button>
                  <template #tip1></template>
                </el-upload>
                <div>
                  <el-button class="sctup" @click="(e)=>{download(index)}">下载</el-button>
                  <el-button class="sctup" @click="showFile">预览</el-button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="flex justify-between">
              <p>自我评价</p>
              <div class="fraction">{{ planList.selfAssessScore }}</div>
            </div>
            <el-form-item
              prop="selfAssessAdvantage"
              label="优势 :"
            >
              <div>{{ planList.selfAssessAdvantage }}</div>
            </el-form-item>
            <el-form-item
              prop="selfAssessImprovement"
              label="劣势 :"
            >
              <div>{{ planList.selfAssessImprovement }}</div>
            </el-form-item>
          </div>

        </div>
        <!--      会审列表-->
        <!-- <div
             class="bg-white border-round-12 padding-tb box-ing padding-lr-xl margin-top">
          <div class="margin-bottom flex justify-between align-center">
            <p>会审复评</p>
          </div>
          <div v-for="(item, index) in planList.jointAssessEntities" :key="index"
               class="jointHearing padding-top-lg">
            <div class="flex justify-between">
              <div class="flex align-center">
                <div class="jointHearing-img flex align-center justify-center">
                  <img v-if="item.avatar&&item.avatar?.length!==0" :src="item?.avatar" />
                  <span v-else> {{ item.jointPerson.slice(-2) }}</span>
                </div>
                <p class="jointHearing-name margin-left">{{ item.jointPerson }}</p>
              </div>
              <div class="fraction">{{ item.jointReviewScore }}</div>
            </div>
            <el-form-item
              label="会审意见 :"
            >
              <div>{{ item.jointReviewAdvantage }}</div>
            </el-form-item>
          </div>
        </div> -->
<div class='flowPath'>
  <h3>流程</h3>
    <div class='today-step'>
      <div class='step-container padding-bottom' v-for="(item,index) in stepList" :key="index">
        <!--        当item 是数组时代表 这是会审人-->
        <template v-if="!isNaN(item.length)">
          <div class='step-round '>
            <img :src="multiplayer"/>
          </div>
          <div class='step-context padding-bottom' style="margin-left:66px;">
            <div class='flex justify-between'>
              <div>
                <h4>会审人</h4>
                <p>{{ item.length }}人会审</p>
              </div>
            </div>
            <div v-for="(info,i) in item" :key="i" class=" margin-top-sm ">
              <p style="text-align:right">{{ info.createTime }}</p>
              <div class="flex align-center justify-between">
                <div class="flex margin-top align-center">
                  <div class="step-multiplayer-img margin-right-sm" style="min-width: 40px;min-height: 40px;">
                    <img v-if="info.avatar?.length!==0" :src="info.avatar"/>
                    <span v-else> {{ info.name.slice(-2) }}</span>
                    <i class="icon" :class="processIcons[info.checkResult]"/>
                  </div>
                  <div class='remark'>
                    {{ info.remark }}
                  </div>
                </div>
              </div>
              <p class="margin-top-sm">{{ info.name }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class='step-round text-center'>
            <img v-if="item.avatar?.length!==0" :src="item.avatar"/>
            <span v-else> {{ item.name.slice(-2) }}</span>
            <i class="icon" :class="processIcons[item.checkResult]"/>
          </div>
          <div class='step-context padding-bottom' style="margin-left:66px;">
            <div class='flex justify-between'>
              <div>
                <h4>{{ index === 0 ? '发起人' : '审批人' }}</h4>
                <p>{{ item.name }}</p>
              </div>
              <p>{{ index === 0 ? item.publishTime : item.createTime }}</p>
            </div>
            <div class='remark' v-if="item.remark !== '' && item.remark !== null">
              {{ item.remark }}
            </div>
          </div>
        </template>
      </div>
    </div>
</div>

        <!--上级-->
        <div class=" padding-lr-xl margin-bottom-30" >
          <div class="flex  jointHearing padding-top justify-between align-center" v-if="planList.reviewEntityList.length>0">
            <p>复评</p>
            <div class="flex align-center justify-center" v-if="sum>0"><span
              style="font-size:16px;color:rgba(0,0,0,0.45);">会审得分均值:</span><span class="fraction">{{ sum }}</span>
            </div>
          </div>
          <div  v-for="(item, index) in planList.reviewEntityList" :key="index"
                class=" padding-top-lg ">
            <div class="flex justify-between">
              <div class="flex align-center ">
                <div class="jointHearing-img flex align-center justify-center ">
                  <img v-if="item.avatar&&item.avatar?.length!==0" :src="item?.avatar" />
                  <span v-else> {{ item.reviewName?.slice(-2) }}</span>
                </div>
                <p class="jointHearing-name margin-left">{{ item.reviewName }}</p>
              </div>
              <div class="fraction">{{ item.superiorReviewScore }}</div>
            </div>
            <el-form-item
              label="优势 :"
            >
              <div>{{ item.superiorReviewAdvantage }}</div>
            </el-form-item>
            <el-form-item
              label="劣势 :"
            >
              <div>{{ item.superiorReviewImprovement }}</div>
            </el-form-item>
          </div>
        </div>
      </el-form>

    </div>

  </div>
</template>
<script>
import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import Flow from '@/components/Flow'
import { seeDetail, viewInfo, personList } from '@/api/user'
import { ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
import multiplayer from '@/assets/images/multiplayer.svg'
export default {
  name: 'staffDrawer',
  props: {
    planId: String,
    userId: String
  },
  setup (props) {
    const route = useRoute()
    const planRef = ref(null)
    const isSuperior = ref(false)
    const planState = ref(1)
    const planUser = ref(' ')
    const isLoading = ref(false)
    const store = useStore()
    const isJoinAssessor = ref(3)
    const sum = ref(0)
    const noSuperiorOperation = ref(1)
    const resubmit = ref(0)
    const client = ref(null)
    const client2 = ref(null)
    const fileList = ref([])
    const fileData = ref([])
    const userid = ref(JSON.parse(sessionStorage.getItem('user')).userid)
    const showHidden = ref(0)
    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const showDoc = ref(false)
    const fileUrl = ref('')
    const fileWidth = ref('min-width = 300px')
    const showPdf = ref(false)
    const pdfUrl = ref('')
    const preview = ref('')
    const stepList = ref([])
    const processIcons = ['process-audit', 'process-success', 'process-failure', '']
    const planList = ref({
      info: [],
      reviewEntityList: [],
      jointAssessEntities: [],
      selfAssessAdvantage: '',
      selfAssessImprovement: '',
      // 上级劣势
      superiorReviewImprovement: '',
      // 上级优势
      superiorReviewAdvantage: '',
      // 上级打分
      superiorReviewScore: undefined,
      publishName: '',
      departmentName: '',
      position: '',
      selfAssessScore: 0,
      jointReviewScore: undefined,
      jointReviewAdvantage: '',
      openReview: 1
    })
    // 页面数据回显
    const getPlanList = (data) => {
      console.log(data)
      data.plan.forEach(item => {
        if (item.attachment) {
          item.attachment = JSON.parse(item.attachment) || []
        }
        if (item.pictures) {
          item.pictures = JSON.parse(item.pictures) || []
        }
      })
      planList.value.publishName = data.publishName
      planList.value.departmentName = data.departmentName
      planList.value.position = data.position
      planList.value.selfAssessScore = data.selfAssessScore
      planList.value.selfAssessAdvantage = data.selfAssessAdvantage
      planList.value.selfAssessImprovement = data.selfAssessImprovement
      planState.value = data.approval.planState
      planUser.value = data.publishId
      planList.value.info = data.plan
      planList.value.jointAssessEntities = data.jointAssessEntities || []
      planList.value.reviewEntityList = data.review || []
      resubmit.value = data.resubmit
      if (data.jointAssessEntities && data.jointAssessEntities.length > 0) {
        let average = 0
        data.jointAssessEntities.forEach(item => {
          average += Number(item.jointReviewScore)
        })
        sum.value = Math.round(average / data.jointAssessEntities.length)
      }
    }
    // 获取自评页面数据
    const getInfo = () => {
      if (!props.planId) {
        return
      }
      viewInfo({ planId: props.planId }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          getPlanList(res.data)
        }
      })
    }
    // 获取审批流程
    const getPersonList = () => {
      personList({
        planId: props.planId,
        userid: props.userId
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          stepList.value = res.data
        }
      })
    }
    const getlist = () => {
      seeDetail({
        planId: props.planId,
        userid: props.userId
      }).then(res => {
        console.log(res)
      })
    }
    const handleRemove = (file, fileList) => {
      if (planUser.value !== userid.value || (Number(planState.value) !== 5)) {
        ElMessage.error('您当前没有此操作权限')
        return
      }
      planList.value.info.forEach(item => {
        item.pictures.forEach((pic, index) => {
          if (pic.uid === file.uid) {
            item.pictures.splice(index, 1)
          }
        })
      })
    }
    const handlePreview = (file) => {
    }
    const handlePictureCardPreview = (file) => {
      dialogImageUrl.value = file.url
      dialogVisible.value = true
    }
    const handleDownload = (file) => {
      console.log(file)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = file.url
      link.download = link.href
      link.click()
    }
    const handleRemove2 = (file1, fileData) => {
    }
    const handlePreview2 = (file1) => {
      preview.value = file1
      console.log(file1)
    }
    const onExceed = () => {
      ElMessage.error('请勿超过上传上限')
    }
    const download = (index) => {
      if (!preview.value.url) {
        return ElMessage.error('请先点击或上传文件')
      }
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = preview.value.url
      link.download = link.href
      link.click()
      preview.value = ''
    }
    const showFile = () => {
      if (!preview.value.url) {
        return ElMessage.error('请先点击或上传文件')
      }
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = 'https://view.officeapps.live.com/op/view.aspx?src=' + preview.value.url
      link.target = '_blank'
      link.click()
      preview.value = ''
    }
    watchEffect(() => {
      if (props.planId) {
        getInfo()
        getPersonList()
      }
      getlist()
      console.log(props.planId)
    })
    return {
      planList,
      planRef,
      isSuperior,
      planState,
      userid,
      planUser,
      isJoinAssessor,
      sum,
      isLoading,
      noSuperiorOperation,
      resubmit,
      client,
      client2,
      handleRemove,
      handlePreview,
      fileList,
      fileData,
      handleRemove2,
      handlePreview2,
      download,
      dialogImageUrl,
      dialogVisible,
      handlePictureCardPreview,
      handleDownload,
      onExceed,
      showFile,
      showDoc,
      fileWidth,
      showPdf,
      pdfUrl,
      fileUrl,
      preview,
      getlist,
      stepList,
      getPersonList,
      processIcons,
      multiplayer
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.info{
  border-top: 1px solid #efefef;
  margin-left: 20px;
  height: 60px;
  line-height: 60px;
}
.PerformanceDetails-name {
  font-size: 20px;
  font-weight: 600;
}
.bage{
  background-color: #f0f2f5;
}
.PerformanceDetails-position {
  font-size: 16px;
}
.el-upload-dragger{
  width: 80px !important;
  height: 25px !important;
  display: inline-block !important;
}
.PerformanceDetails {
  overflow: hidden;
  background-color: #f0f2f5;
  .fraction {
    color: #fb4343;
    font-size: 24px;
    font-weight: 600;
  }

  .content-box {
    height: calc(100vh - 122px);
    overflow: auto;
    width: 96%;
    margin: 0 auto;
    .el-button--small::v-deep {
      padding: 8px 16px;
      color: #1890ff;
      border-color: #1890ff;
      border-radius: 8px;
      font-size: 16px;
    }
  }
  .sctup{
    background: #fff   !important;
    width: 80px ;
    height: 25px;
    margin:10px 20px;
    border: 0 !important;
    text-align: center;
  }
  .zdxz{
    color:#c1c1c1;
    font-size: 14px;
    margin-left: 20px;
  }

  &-form-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &-form::v-deep {
    .el-input__inner{
      padding:0 8px;
    }
    .el-form-item__label {
      float: none;
    }

    label {
      width: auto !important;
    }

    .el-form-item {
      margin-bottom: 8px;
    }

    .el-form-item__content {
      margin: 0 !important;
    }
    .el-input-number {
      .el-input__inner {
        padding: 0 15px;
        text-align:left;
      }
    }
  }

}

.jointHearing {
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  &-name {
    font-size: 20px;
    margin-right: 40px;
  }

  &-position {
    font-size: 20px;
  }

  &-img {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    background: #3296FA;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }

    span {
      font-size: 12px;
      color: #fff;
    }
  }
}
.flowPath{
      width: 96%;
    margin: 40px auto;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;

.today-step {
  padding-top: 30px;
  span {
    color: #fff;
  }

  .step-container {
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 30px;
      left: 18px;
      height: 100%;
      width: 4px;
      background: #dce3eb;
    }
  }

  .step-container:last-child::before {
    display: none;
  }

  .step-context {

    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;

    span {
      font-size: 16px;
    }
  }

  h4 {
    margin-bottom: 4px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  .step-round {
    position: absolute;
    left: 0;
    width: 42px;
    height: 42px;
    background: #1890FF;
    border-radius: 8px;
    line-height: 42px;

    img {
      height: 42px;
      border-radius: 8px;
      width: 42px;
    }

    i {
      width: 14px;
      height: 14px;
      position: absolute;
      right: -2px;
      bottom: 0;
    }
  }

  .step-multiplayer-img {
    position: relative;
    background: #1890FF;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;

    span {
      font-size: 10px;
    }

    img {
      height: 40px;
      width: 40px;
      border-radius: 8px;
    }

    i {
      width: 14px;
      height: 14px;
      position: absolute;
      right: -2px;
      bottom: 0;
    }
  }

  .remark {
    padding: 8px;
    background: #f1f4f5;
    border-radius: 8px;
  }
}
}

</style>
